import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { axios } from '../../api';
import { Pencil } from '../../assets/Pencil';
import { formatDateTime } from '../../dateUtils';
import { formatPhoneNumber } from '../../phoneUtils';
import { BOTTOM_TOAST, generateErrorMsg } from '../../toastUtils';
import { Button } from '../Button';
import { DefaultPasswordUpdate } from '../DefaultPasswordUpdate';
import { TextInput } from '../TextInput';

import { Customer } from '@/pages/types';

export const AccountAndContactInfo = ({ customer }: { customer: Customer }) => {
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [updatedEmail, setUpdatedEmail] = useState<string>('');

  return (
    <div className="flex-col">
      <h2 className="mb-1 flex items-center">
        <b className="inline-block">Customer Details</b>
        <div className="text-sm ml-2">
          <Link className="cursor-pointer underline text-anchor" to="changelog">
            View Changelog
          </Link>
        </div>
      </h2>
      <div className="bg-white border rounded-lg p-4 shadow-md">
        <div className="mb-2">
          <strong>Customer ID:</strong> {customer.id}
        </div>
        <div className="mb-2 flex items-center">
          <strong>Email address:</strong>{' '}
          {!isEditingEmail && (
            <div className="flex items-center ml-1">
              <span>{customer.email}</span>
              {customer.status !== 'CANCELLED' && (
                <a
                  onClick={() => {
                    setIsEditingEmail(true);
                  }}
                  className="cursor-pointer underline text-anchor ml-1"
                >
                  <Pencil className="w-4 h-4 text-black" />
                </a>
              )}
            </div>
          )}
          {isEditingEmail && (
            <div className="text-sm ml-2 flex items-center">
              <div className="w-56 h-[20px]">
                <TextInput
                  value={updatedEmail}
                  placeholder="New Email"
                  setTextChange={setUpdatedEmail}
                  className="py-0 px-2"
                />
              </div>
              <a
                onClick={() => setIsEditingEmail(false)}
                className="cursor-pointer underline text-anchor ml-2"
              >
                Cancel
              </a>{' '}
              <Button
                label="Save"
                variant="primary"
                className="ml-2 h-6 min-w-[30px]"
                onClick={() =>
                  axios
                    .put(`accounts/${customer.id}/update_customer_email`, {
                      new_email: updatedEmail,
                    })
                    .then(() => {
                      // TODO: Add refresh customer here
                      window.location.reload();
                    })
                    .catch((e) => {
                      toast.error(generateErrorMsg(e), BOTTOM_TOAST);
                    })
                }
              />
            </div>
          )}
          <DefaultPasswordUpdate email={customer.email} />
        </div>
        <div className="mb-2">
          <strong>Phone number:</strong> {formatPhoneNumber(customer.phone_number)}
        </div>
        <div className="mb-2">
          <strong>Account status:</strong> {customer.status}
        </div>
        <div className="mb-2">
          <strong>Customer since:</strong> {formatDateTime(customer.created)}
        </div>
        <div className="mb-2">
          <strong>Pricing Model:</strong> {customer.default_pricing_model?.name}
        </div>
      </div>
    </div>
  );
};
