import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ConfirmationModal } from './ConfirmationModal';
import { axios } from '../api';
import { Key } from '../assets/Key';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

interface DefaultPasswordUpdateProps {
  email: string;
}

export const DefaultPasswordUpdate = ({ email }: DefaultPasswordUpdateProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleResetPassword = () => {
    axios
      .post(`default_password_request`, {
        email,
      })
      .then(() => {
        toast.success(`Success! Password updated for ${email}.`, BOTTOM_TOAST);
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
    setShowConfirmModal(false);
  };
  if (email) {
    return (
      <div className="flex justify-between items-center">
        <Key className="inline w-5 h-5 cursor-pointer" onClick={() => setShowConfirmModal(true)} />
        {showConfirmModal && (
          <ConfirmationModal
            title={`Are you sure you want to hard reset this customer's password?`}
            customMessage={
              <div>
                <p className="text-sm mb-4 text-center text-red-600">
                  By proceeding, you will be updating the customer's password with the default
                  password below.
                </p>
                <p className="text-sm mb-4 bold text-center text-red-600">
                  <b>Default password: Unkibble1!</b>
                </p>
              </div>
            }
            confirmLabel="Reset Password"
            onConfirm={handleResetPassword}
            onCancel={() => setShowConfirmModal(false)}
          />
        )}
      </div>
    );
  }

  return <Key className="w-5 h-5" />;
};
